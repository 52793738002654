import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { TUseUpdateGateCallbacksType } from '@/hooks/gates/useUpdateGates';

import { gatesActions, gatesSelectors } from '@/store/slices/gates';
import { chartSettingsSelectors } from '@/store/slices/chartSettings';
import { experimentSelectors } from '@/store/slices/experiment';
import { appAPI } from '@/store/services/app';

import { useConfirmationModalContext } from '@/components/common/ConfirmationModalProvider';

import { getDetailsLists } from '@/helpers/datasetDetails';
import { filterGateListByEntityLevel, findParentGate } from '@/helpers/gates';
import { isPolarAreaTypeGate } from '@/helpers/typeGuards';

import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';

import { EPageWithChartType } from '@/types/charts';

import useParamsExperimentId from '../useParamsExperimentId';
import { useExperimentContext } from '../useExperimentContext';

type TGatesShapeOverridesPayload = {
  updateGate: TUseUpdateGateCallbacksType['updateGate'];
  currentAppLane: Nullable<TLane>;
  gate: Nullable<TGate>;
  pageType: EPageWithChartType;
};

export function useGatesShapeOverrides({ updateGate, currentAppLane, gate, pageType }: TGatesShapeOverridesPayload) {
  const confirmationModal = useConfirmationModalContext();
  const experimentId = useParamsExperimentId();
  const chartId = usePlotChartIdContext();

  const appDispatch = useAppDispatch();

  const scanList = useSelector(experimentSelectors.selectCurrentScanList);
  const activeGate = useSelector(gatesSelectors.selectActiveGate);
  const fullScreenChartData = useSelector(chartSettingsSelectors.selectFullScreenChartData);
  const { chartDataList: selectedDatasets } = useExperimentContext();

  const { data: fullGateList = [] } = appAPI.useFetchExperimentGatesQuery(experimentId);
  const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled(chartId));
  const isCageLvlForced = useSelector(chartSettingsSelectors.selectIsCageLvlForced(chartId));
  const entityLevelGateList = useMemo(
    () => filterGateListByEntityLevel(fullGateList, isObjectEntityEnabled && !isCageLvlForced) ?? [],
    [fullGateList, isObjectEntityEnabled, isCageLvlForced]
  );

  const datasetDetailsList = useMemo(() => getDetailsLists(scanList).datasetDetailsList, [scanList]);

  // hide this option if the shape for the current dataset is equal to the gate's global shape
  const isResetToGlobalGateHidden = useMemo(() => {
    if (!gate) return true;
    const isPolarSector = isPolarAreaTypeGate(gate.shape);
    const polarSectorParent = isPolarSector ? findParentGate(entityLevelGateList, gate.parentId) : null;
    const gateToValidate = isPolarSector && polarSectorParent ? polarSectorParent : gate;

    const currentGateOverrides =
      Array.isArray(gateToValidate?.overrides) && gateToValidate?.overrides.length ? gateToValidate.overrides : [];

    const overridesForDataset = currentGateOverrides.find(
      (overrides) =>
        overrides.scanId === currentAppLane?.dataset.scanId && overrides.laneId === currentAppLane.dataset.laneId
    );

    if (!overridesForDataset) return true;

    return isEqual(overridesForDataset.shape, gateToValidate.shape);
  }, [gate, currentAppLane, entityLevelGateList]);

  // hide this option if the dataset does not have an adjusted shape or there are no datasets to which the current gate shape can be applied
  const isApplyGateGloballyHidden = useMemo(() => {
    if (!gate || !currentAppLane) return true;

    const isPolarSector = isPolarAreaTypeGate(gate.shape);
    const polarSectorParent = isPolarSector ? findParentGate(entityLevelGateList, gate.parentId) : null;
    const gateToValidate = isPolarSector && polarSectorParent ? polarSectorParent : gate;

    let overridesForCurrentDataset: Nullable<any> = null;

    const currentGateOverrides =
      Array.isArray(gateToValidate?.overrides) && gateToValidate?.overrides.length ? gateToValidate.overrides : [];

    const overridesForOtherDatasets = currentGateOverrides.filter((overrides) => {
      if (overrides.scanId === currentAppLane?.dataset.scanId && overrides.laneId === currentAppLane.dataset.laneId) {
        overridesForCurrentDataset = { ...overrides };
        return false;
      }
      return true;
    });

    if (!overridesForCurrentDataset?.shape) {
      return !overridesForOtherDatasets.length;
    }

    if (datasetDetailsList.length > currentGateOverrides.length) return false;

    const isShapesEqualForAllDatasets = overridesForOtherDatasets.every((overrides) =>
      isEqual(overrides.shape, overridesForCurrentDataset?.shape)
    );
    return isShapesEqualForAllDatasets;
  }, [gate, currentAppLane, entityLevelGateList]);

  const isApplyGateToSelectedHidden = useMemo(() => {
    if (fullScreenChartData) return isApplyGateGloballyHidden;
    return pageType !== EPageWithChartType.matrixView || isApplyGateGloballyHidden;
  }, [pageType, fullScreenChartData, isApplyGateGloballyHidden]);

  const onGateUpdateSuccess = (newActiveGate: TGate) => appDispatch(gatesActions.setActiveGate(newActiveGate));

  const applyGateTo = useCallback(
    async (applyToSelected = false) => {
      if (!gate) return;
      let gateToUpdate = { ...gate };

      if (isPolarAreaTypeGate(gate.shape)) {
        const polarSectorParent = findParentGate(entityLevelGateList, gate.parentId);

        if (polarSectorParent) {
          gateToUpdate = polarSectorParent;
        }
      }

      const substr = applyToSelected ? 'selected' : 'all';
      const confirmationBtnTitle = applyToSelected ? 'Apply to selected' : 'Apply globally';

      const result = await confirmationModal.onOpen({
        confirmationText: `Are you sure? The currently displayed gate will be used for ${substr} datasets. This cannot be undone.`,
        approveButtonText: confirmationBtnTitle,
      });

      if (!result) return;

      const newGateShapeOverrideList: TGatesShapeOverrides[] = [];
      const gateShapeOverrides: TGatesShapeOverrides[] =
        Array.isArray(gateToUpdate?.overrides) && gateToUpdate?.overrides.length ? gateToUpdate?.overrides : [];

      const currentOverrideData = gateShapeOverrides.find(
        ({ scanId, laneId }) => currentAppLane?.dataset?.scanId === scanId && currentAppLane?.dataset?.laneId === laneId
      );

      const datasetsForGatesOverride = applyToSelected ? selectedDatasets : datasetDetailsList;

      if (currentOverrideData) {
        datasetsForGatesOverride.forEach((datasetInfo) => {
          if (currentOverrideData) {
            const newOverrideItem: TGatesShapeOverrides = {
              scanId: datasetInfo.scanId,
              laneId: datasetInfo.laneId,
              shape: currentOverrideData.shape,
            };

            newGateShapeOverrideList.push(newOverrideItem);
          }
        });
      }

      const gateDataToUpdate = {
        overrides: [...newGateShapeOverrideList],
      };

      updateGate(gateToUpdate, { ...gateDataToUpdate }, () => {
        if (activeGate) {
          const updatedActiveGate: TGate = {
            ...activeGate,
            ...gateDataToUpdate,
          };
          onGateUpdateSuccess(updatedActiveGate);
        }
      });
    },
    [
      currentAppLane?.dataset?.scanId,
      currentAppLane?.dataset?.laneId,
      activeGate,
      datasetDetailsList,
      gate,
      entityLevelGateList,
    ]
  );

  const resetGateToDefault = useCallback(async () => {
    if (!gate) return;

    let gateToUpdate = { ...gate };

    if (isPolarAreaTypeGate(gate.shape)) {
      const polarSectorParent = findParentGate(entityLevelGateList, gate.parentId);

      if (polarSectorParent) {
        gateToUpdate = polarSectorParent;
      }
    }

    const result = await confirmationModal.onOpen({
      confirmationText: `Are you sure? The currently displayed gate will be rolled back to the default gate state. This cannot be undone.`,
      approveButtonText: 'Undo adjustments',
    });

    if (!result) return;

    const gateShapeOverrides: TGatesShapeOverrides[] =
      Array.isArray(gateToUpdate?.overrides) && gateToUpdate?.overrides.length ? gateToUpdate?.overrides : [];

    const overridesDataToUpdate = gateShapeOverrides.filter(
      (overrideData) =>
        overrideData.scanId !== currentAppLane?.dataset.scanId || overrideData.laneId !== currentAppLane?.dataset.laneId
    );

    const gateDataToUpdate = {
      overrides: [...overridesDataToUpdate],
    };

    updateGate(gateToUpdate, { ...gateDataToUpdate }, () => {
      if (activeGate) {
        const updatedActiveGate: TGate = {
          ...activeGate,
          ...gateDataToUpdate,
        };
        onGateUpdateSuccess(updatedActiveGate);
      }
    });
  }, [
    currentAppLane?.dataset?.scanId,
    currentAppLane?.dataset?.laneId,
    activeGate,
    datasetDetailsList,
    gate,
    entityLevelGateList,
  ]);

  return {
    applyGateTo,
    resetGateToDefault,
    isApplyGateGloballyHidden,
    isResetToGlobalGateHidden,
    isApplyGateToSelectedHidden,
  };
}
