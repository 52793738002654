import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { experimentSelectors } from '@/store/slices/experiment';

import ExperimentPageHeader from '@/components/common/ExperimentPageHeader';
import ExperimentPageDefaultHeaderControls from '@/components/common/ExperimentPageHeader/ExperimentPageDefaultHeaderControls';
import Modal from '@/components/common/Modal';
import { chartSettingsSelectors } from '@/store/slices/chartSettings';
import { useChartWithGatesDefaultProps } from '@/hooks/useChartWithGatesDefaultProps';
import DatasetChartHeading from '@/components/charts/DatasetChartHeading';
import SingleChartWithGates from '@/components/charts/SingleChartWithGates';
import GateList from '@/pages/Analytics/components/Scatterplot/components/GateList';
import DatasetChart from '@/pages/Dataset/components/DatasetChart';
import { EPageWithChartType } from '@/types/charts';
import { EAxesGroupName } from '@/store/slices/scatterplots';
import Button from '@/components/common/Button';
import CheckboxInput from '@/components/common/CheckboxInput';

import styles from './DatasetFullScreen.module.scss';

const cn = classnames.bind(styles);

type TDatasetFullScreenProps = {
  isOpen: boolean;
  closeModal: () => void;
  handleSaveClick: () => void;
  handleChangeScan: (datasetDetailsName: string) => (scanId?: string) => void;
  toggleShouldApplyChangesToAllDatasets: () => void;
  shouldApplyChangesToAllDatasets: boolean;
  isShouldApplyChangesToAllDatasetsVisible: boolean;
  handleChangeChannel: (datasetDetailsName: string) => (channelId?: string) => void;
};

const DatasetFullScreen = ({
  isOpen,
  closeModal,
  handleSaveClick,
  handleChangeScan,
  toggleShouldApplyChangesToAllDatasets,
  shouldApplyChangesToAllDatasets,
  isShouldApplyChangesToAllDatasetsVisible,
  handleChangeChannel,
}: TDatasetFullScreenProps) => {
  const experimentData = useSelector(experimentSelectors.selectCurrentExperiment);
  const fullScreenChartData = useSelector(chartSettingsSelectors.selectFullScreenChartData);
  const scan = useSelector(experimentSelectors.selectScan(fullScreenChartData?.scanId ?? ''));
  const chartLane = useSelector(
    experimentSelectors.selectLane(fullScreenChartData?.scanId ?? '', fullScreenChartData?.laneId ?? '')
  );
  const totalCages = useMemo(
    () => experimentData?.numberOfCages ?? scan?.summary.numberOfCages,
    [scan?.summary, experimentData?.numberOfCages]
  );

  const { allProps, ...componentsProps } = useChartWithGatesDefaultProps({
    specificAppLane: chartLane,
    specificChangeScanHandler: handleChangeScan(fullScreenChartData?.id ?? ''),
    specificChangeChannelHandler: handleChangeChannel(fullScreenChartData?.id ?? ''),
  });

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} shouldCloseOnOverlayClick className={cn('modal')} isFullScreen>
      <Modal.Header onRequestClose={closeModal} className={cn('modal__header')}>
        <div id="modal-header-block" className={cn('modal__header-block')} />
        <ExperimentPageHeader
          title={experimentData?.experimentName ?? ''}
          experimentData={experimentData}
          totalCages={totalCages}
          isBasicInfoHidden
          portalId="modal-header-block"
          experimentControls={<ExperimentPageDefaultHeaderControls />}
        />
      </Modal.Header>
      <Modal.Content className={cn('modal__content')}>
        <SingleChartWithGates
          ChartHeadingComponent={DatasetChartHeading}
          ChartComponent={DatasetChart}
          GatesComponent={GateList}
          pageType={EPageWithChartType.singleChart}
          {...componentsProps}
          className={cn('modal__chart-with-gates')}
          customGateListClassName={cn('modal__gate-list')}
          specificAxesGroupName={EAxesGroupName.singleChart}
          asideChildren={
            <div className={cn('modal__controls')}>
              <div>
                {isShouldApplyChangesToAllDatasetsVisible && (
                  <CheckboxInput
                    checked={shouldApplyChangesToAllDatasets}
                    onChange={toggleShouldApplyChangesToAllDatasets}
                    theme="light"
                    label="Apply to all datasets"
                    className={cn('modal__checkbox')}
                  />
                )}
              </div>
              <Button color="white" className={cn('modal__button')} onClick={closeModal}>
                Cancel
              </Button>
              <Button color="yellow" className={cn('modal__button')} onClick={handleSaveClick}>
                Save
              </Button>
            </div>
          }
        />
      </Modal.Content>
    </Modal>
  );
};

export default memo(DatasetFullScreen);
